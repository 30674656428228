<template>
  <div>
    <div class="flex flex-wrap gap-x-3 gap-y-1">
      <div class="flex flex-wrap gap-1">
        <button @click="confirmSaveEmployees" class="tw--btn" :disabled="sumClosed == sumAll.items">Zapisz pdf-y dla Agentów</button>
        <button @click="downloadCumulated" class="tw--btn">Wydrukuj zbiorczo faktury</button>
      </div>

      <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
        <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">ilość wygenerowanych faktur</span>
        <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ sumAll.items }}</span>
      </div>
      <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
        <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">suma do wypłaty</span>
        <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ sumAll.amount | moneyPL }}</span>
      </div>

    </div>

    <div class="text-sm text-muted italic mt-3">* kliknij podwójnie myszką w kwotę, aby edytować, jeśli faktury nie są jeszcze zapisane w formie pdf dla agentów</div>

    <div class="hidden xl:flex flex-wrap 2xl:flex-nowrap border dark:border-gray-600 rounded my-1 text-sm text-bright">
      <div class="order-3 w-full xl:w-1/4 flex flex-wrap items-center px-2">
        <div class="w-full lg:w-1/3 flex gap-x-1">
          data wyst.
        </div>
        <div class="w-full lg:w-1/3 flex gap-x-1">
          mc sprzed.
        </div>
        <div class="w-full lg:w-1/3 flex gap-x-1">
          data płatn.
        </div>
      </div>
      <div class="order-1 xl:order-4 w-2/3 xl:w-1/6 self-center px-2">nr faktury</div>
      <div class="order-5 flex-grow min-w-0 flex flex-col self-center px-2">
        agent / dane firmy
      </div>
      <div class="order-6 w-24 flex items-end xl:items-center justify-start sm:justify-end px-2">
        kwota
      </div>
      <div class="order-2 xl:order-last w-1/3 xl:w-12 flex items-center justify-end px-2">
        &nbsp;
      </div>
    </div>

    <div v-if="invoices.length === 0" class="text-base text-bright">Nie wygenerowano faktur w miesiącu {{ activeFilters.year }}-{{ activeFilters.month }}</div>

    <AdminInvoicesEditItem
      v-for="invoice in invoices"
      @delInv="confirmDelInv"
      @invoiceUpdated="invoiceUpdated"
      :invoice="invoice"
      :key="invoice.id"
    />

  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import InvoiceService from '../../services/InvoiceService';
import AdminInvoicesEditItem from './AdminInvoicesEditItem.vue';

export default {
  name: 'AdminInvoicesEdit',
  components: {
    AdminInvoicesEditItem,
  },
  mixins: [confirm, errorHandler],
  props: {
    activeFilters: {
      required: true,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      invoices: [],
      saveErrors: {},
    };
  },
  computed: {
    sumClosed() {
      let sum = 0;
      this.invoices.forEach((obj) => {
        if (obj.closed === 1 || obj.closed === '1') { sum += 1; }
      });
      return sum;
    },
    sumAll() {
      const sum = { items: 0, amount: 0 };
      this.invoices.forEach((obj) => {
        sum.items += 1;
        sum.amount += Number(obj.amount);
      });
      return sum;
    },
    downloadCumulatedUrl() {
      return `${this.apiUrl}/print-invoice/${this.activeFilters.year}/${this.activeFilters.month}/0/1`;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getInvoices();
    },
    getInvoices() {
      this.$store.dispatch('setLoadingData', true);
      InvoiceService.getInvoicesToEdit(this.activeFilters)
        .then((response) => {
          this.invoices = response.data.invoices;
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    confirmDelInv(id) {
      this.confirm(() => this.delInv(id), 'Potwierdź usunięcie pozycji');
    },
    delInv(id) {
      InvoiceService.deleteInvoice(id)
        .then((response) => {
          this.invoices = this.invoices.filter(obj => Number(obj.id) !== Number(id));
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
        })
        .catch((error) =>  this.saveErrors = this.resolveError(error));
    },
    confirmSaveEmployees() {
      this.confirm(() => this.saveForEmployees(), 'Ta czynność spowoduje wystawienie faktur i udostępnienie w panelu agentów. Po tej czynności faktur nie będzie można edytować.<br>Potwierdź operację lub anuluj i wróć do edycji.');
    },
    saveForEmployees() {
      this.$store.dispatch('setLoadingData', true);
      InvoiceService.saveInvoicesForEmployees(this.activeFilters.year, this.activeFilters.month)
        .then((response) => {
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.getInvoices();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch(error => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    invoiceUpdated(invoice) {
      const updInvoice = this.invoices.find(obj => Number(obj.id) === Number(invoice.id));
      Object.assign(updInvoice, invoice);
    },
    downloadCumulated() {
      const newWindow = window.open(null, '');
      newWindow.location.href = `${this.apiUrl}/print-invoice/${this.activeFilters.year}/${this.activeFilters.month}/0/1`;
    },
  },
};
</script>
