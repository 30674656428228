<template>
  <div class="flex flex-col gap-y-2">
    <div id="invoices-admin-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="inkaso-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="getProvisionList"
                v-model="activeFilters.year"
                label="Rok"
                :options="years"
                :nullOption="false"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="getProvisionList"
                v-model="activeFilters.month"
                label="Miesiąc należności raty"
                :options="months"
                :nullOption="false"
              />

              <div class="flex">
                <button @click="showInvoices=!showInvoices" class="tw--btn rounded-r-none" :class="{'tw--btn-secondary disabled:text-black dark:disabled:text-white': !showInvoices}" :disabled="!showInvoices">
                  Generowanie faktur
                </button>
                <button @click="showInvoices=!showInvoices" class="tw--btn rounded-l-none" :class="{'tw--btn-secondary disabled:text-black dark:disabled:text-white': showInvoices}" :disabled="showInvoices">
                  Edycja/druk faktur
                </button>
              </div>
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="invoices-admin-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

      <div v-show="!showInvoices">
        <div class="flex flex-wrap gap-x-3 gap-y-1">
          <button @click="checkAllProv" class="tw--btn-icon flex items-center gap-1" title="Zanzacz/odznacz wszystko">
            <FontAwesomeIcon :icon="checkedAllProv ? ['far', 'minus-square'] : ['far', 'check-square']" size="lg" class="p-0" />
            <!-- <span>{{ checkedAll ? 'usuń zanzaczenie' : 'zaznacz wszystkie' }}</span> -->
          </button>
          <div class="flex flex-wrap gap-x-3 gap-y-1">
            <button v-show="!showInvoices" @click="generateInvoices" class="tw--btn">Generuj zaznaczone faktury</button>
          </div>
          <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
            <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">ilość pozycji</span>
            <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ paymentList.length }}</span>
          </div>
          <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
            <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">suma do wypłaty</span>
            <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ sumAllToPay | moneyPL }}</span>
          </div>
        </div>

        <div id="invoices-to-generate-table" class="flex flex-col gap-1 mt-3">
          <div class="flex flex-wrap lg:flex-nowrap border dark:border-gray-600 rounded shadow py-1" v-for="(prov, index) in paymentList" :key="`prov-${index}`">

            <div class="order-1 flex-shrink-0 w-1/2 lg:w-8 flex items-center lg:justify-center px-2">
              <label><input type="checkbox" true-value="1" false-value="0" v-model="prov.checked"></label>
            </div>
            <div class="order-3 flex-grow min-w-0 self-center flex flex-col xl:flex-row xl:items-center px-2">
              <div class="w-full xl:w-2/5">{{ prov.empl_name }}</div>
              <div class="w-full xl:w-3/5 text-xs">{{ prov.company_name }}</div>
            </div>
            <div class="order-5 flex-shrink-0 w-full lg:w-40 self-center px-2">
              <span class="text-muted">NIP:&nbsp;</span><span v-if="prov.nip">{{ prov.nip }}</span><span v-else class="italic text-sm text-yellow-500 dark:text-yellow-700">brak NIP</span></div>
            <div class="order-6 flex-shrink-0 w-full lg:w-60 flex flex-col self-center items-end px-2">
              <div v-for="(subp, index) in prov.subpayments" :key="`sub-${index}`" class="flex gap-x-2 text-xs italic text-muted">
                <div><FontAwesomeIcon icon="user-tie" /> {{ subp.empl_name }}</div>
                <div>{{ subp.owfca_value | moneyPL }}</div>
              </div>
            </div>
            <div class="order-2 flex-shrink-0 lg:order-7 w-1/2 lg:w-28 text-right self-center px-2">{{ prov.agent_value | moneyPL }}</div>

          </div>
        </div>

      </div>

      <AdminInvoicesEdit v-if="showInvoices && !loading" :activeFilters="activeFilters" />

    </div>
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import moment from 'moment';
import InvoiceService from '../../services/InvoiceService';
import AdminInvoicesEdit from './AdminInvoicesEdit.vue';

export default {
  name: 'AdminInvoices',
  components: {
    AdminInvoicesEdit,
  },
  mixins: [errorHandler],
  data() {
    return {
      paymentList: [],
      activeFilters: {
        year: '',
        month: '',
      },
      showInvoices: false,
      saveErrors: {},
      loading: false,
    };
  },
  computed: {
    years() {
      const startY = moment().subtract(1, 'years').format('YYYY');
      const yearsArr = [];
      for (let i = 0; i < 10; i += 1) {
        yearsArr.push({ id: Number(startY) + i, name: Number(startY) + i });
      }
      return yearsArr;
    },
    months() {
      const months = [];
      for (let index = 1; index < 13; index++) {
        const month = (`${index}`).length === 1 ? `0${index}` : `${index}`;
        months.push({ id: month, name: `${index}` });
      }
      return months;
    },
    sumAllToPay() {
      let sum = 0;
      this.paymentList.forEach((obj) => { sum += Number(obj.agent_value); });
      return sum;
    },
    checkedAllProv() {
      return this.paymentList.find(obj => obj.checked === 0 || obj.checked === '0' || obj.checked === undefined) === undefined;
    },
    checkedProv() {
      let sum = 0;
      this.paymentList.forEach((obj) => { sum += Number(obj.checked); });
      return sum;
    },
  },
  mounted() {
    this.activeFilters.year = moment().format('YYYY');
    this.activeFilters.month = moment().format('MM');
    this.init();
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getProvisionList();
    },
    getProvisionList() {
      this.loading = true;
      this.$store.dispatch('setLoadingData', true);
      InvoiceService.getProvisionListToPay(this.activeFilters)
        .then((response) => {
          this.paymentList = response.data.paymentList;
          this.paymentList.forEach((obj) => { obj.checked = Number(obj.checked); });
          this.setUrl();
          this.loading = false;
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.loading = false;
          this.saveErrors = this.resolveError(error);
        })
        .then(() => { this.$store.dispatch('setLoadingData', false); });
    },
    generateInvoices() {
      this.$store.dispatch('setLoadingData', true);
      const checked = this.paymentList.filter(obj => obj.checked === 1 || obj.checked === '1');
      const toCreate = [];
      checked.forEach((obj) => {
        toCreate.push({ employee_id: obj.empl_id, value: obj.agent_value });
      });
      InvoiceService.createAutoInvoices(toCreate)
        .then(() => {
          this.getProvisionList();
          this.showInvoices = true;
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
            this.saveErrors = this.resolveError(error);
            this.$store.dispatch('setLoadingData', false);
        });
    },
    checkAllProv() {
      let chk = 1;
      if (this.checkedAllProv) {
        chk = 0;
      }
      this.paymentList.forEach((obj) => { obj.checked = chk; });
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'AdminInvoices', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.activeFilters.year = moment().format('YYYY');
      this.activeFilters.month = moment().format('MM');
      this.getProvisionList();
    },
  },
};
</script>
