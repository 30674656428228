<template>
  <div class="flex flex-wrap 2xl:flex-nowrap border dark:border-gray-600 rounded my-1 text-sm">
    <div class="order-3 w-full xl:w-1/4 flex flex-wrap items-center px-2">
      <div class="w-full lg:w-1/3 flex gap-x-1">
        <div class="block xl:hidden text-muted"><FontAwesomeIcon :icon="['far', 'calendar-alt']" />&nbsp;wystawiona</div><div>{{ invoice.invoice_date_h }}</div>
      </div>
      <div class="w-full lg:w-1/3 flex gap-x-1" @dblclick="edit('sale_date_h')">
        <div class="block xl:hidden text-muted"><FontAwesomeIcon :icon="['far', 'calendar-alt']" />&nbsp;sprzedaż</div>
        <div v-if="!edited.sale_date_h">{{ invoice.sale_date_h }}</div>
        <div v-else>
          <select v-model="fieldCache.sale_date_h" @keydown.esc="cancel" @blur="cancel" @change="save" ref="sale_date_h">
            <option v-for="(saleDateOption, index) in saleDateOptions" :key="index" :value="saleDateOption">{{ saleDateOption }}</option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-1/3 flex gap-x-1">
        <div class="block xl:hidden text-muted"><FontAwesomeIcon :icon="['far', 'calendar-alt']" />&nbsp;płatność</div><div>{{ invoice.due_date_h }}</div>
      </div>
    </div>
    <div class="order-1 xl:order-4 w-2/3 xl:w-1/6 self-center px-2">{{ invoice.invoice_full_no }}</div>
    <div class="order-5 w-full flex-grow-0 sm:flex-grow sm:min-w-0 sm:w-auto flex flex-col px-2">
      <div class="flex gap-x-1">
        <div class="text-muted">agent:</div>
        <div>{{ invoice.employee_name }}</div>
      </div>
      <div>{{ invoice.employee_company_name }}</div>
      <div class="">{{ invoice.address_l1 }}&nbsp;{{ invoice.address_l2 }}</div>
      <div>NIP: {{ invoice.nip }}</div>
      <div class="has-text-left">{{ invoice.bank_acc_no | bankAcc }}</div>
    </div>
    <div class="order-6 w-24 flex items-end xl:items-center justify-start sm:justify-end px-2" @dblclick="edit('amount')">
      <span v-if="!edited.amount">{{ invoice.amount | moneyPL }}</span>
      <div v-else>
        <input @keydown.enter="save" @keydown.esc="cancel" @blur="cancel" class="text-right" type="text" v-model="fieldCache.amount" ref="amount">
        <p v-if="saveErrors.amount != undefined" class="text-sm text-red-700">{{ saveErrors.amount }}</p>
      </div>
    </div>
    <div class="order-2 xl:order-last w-1/3 xl:w-12 flex items-center justify-end px-2">
      <button @click="delInv(invoice.id)" v-show="invoice.closed==0" class="tw--btn-icon tw--btn-icon-danger" title="Usuń pozycję z listy">
        <FontAwesomeIcon :icon="['far', 'trash-alt']" size="lg" />
      </button>
      <button @click="getInvoice" v-show="invoice.closed==1" class="tw--btn-icon" title="Pobierz fakturę">
        <FontAwesomeIcon icon="cloud-download-alt" size="lg" />
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { errorHandler } from '../../mixins/errorHandler';
import InvoiceService from '../../services/InvoiceService';

export default {
  name: 'AdminInvoicesEditItem',
  mixins: [errorHandler],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      edited: {
        amount: false,
        sale_date_h: false,
      },
      fieldCache: {},
      saveErrors: {},
    };
  },
  computed: {
    saleDateOptions() {
      const options = [];
      options.push(moment(this.invoice.invoice_date_h).subtract(1, 'month').format('YYYY-MM'));
      options.push(moment(this.invoice.invoice_date_h).format('YYYY-MM'));
      return options;
    },
  },
  methods: {
    delInv(id) {
      this.$emit('delInv', id);
    },
    edit(field) {
      if (this.invoice.closed === 1 || this.invoice.closed === '1') {
        this.$store.dispatch('notifications/add', { message: 'Ta polisa już została wystawiona i wysłana do Agenta - nie mozna jej edytować!', type: 'warning' });
      } else {
        if (['amount'].includes(field)) {
          this.fieldCache[field] = this.invoice[field].replace('.', ',');
        } else {
          this.fieldCache[field] = this.invoice[field];
        }
        this.edited[field] = true;
        this.$nextTick(() => {
          if (this.$refs.amount !== undefined) {
            this.$refs[field].select();
          }
          this.$refs[field].focus();
        });
        this.edited[field] = true;
      }
    },
    cancel() {
      Object.keys(this.edited).forEach((key) => { this.edited[key] = false; });
      this.fieldCache = {};
      this.saveErrors = {};
    },
    save() {
      this.saveErrors = {};
      if (this.validate()) {
        InvoiceService.updateInvoice(this.invoice.id, this.fieldCache)
          .then((response) => {
            Object.keys(this.edited).forEach((key) => { this.edited[key] = false; });
            this.fieldCache = {};
            this.$emit('invoiceUpdated', response.data.invoice);
          })
          .catch((error) => {
            this.saveErrors = this.resolveError(error);
          });
      }
    },
    validate() {
      let result = true;
      Object.keys(this.fieldCache).forEach((key) => {
        if (['amount'].includes(key)) {
          const numberValue = Number((this.fieldCache[key].replace(',', '.')));
          if (Number.isNaN(numberValue)) {
            this.saveErrors[key] = 'Niepoprawny format liczby';
            result = false;
          }
        }
      });
      return result;
    },
    getInvoice() {
      const newWindow = window.open(null, '');
      newWindow.location.href = `${this.apiUrl}/invoice/show-file/${this.invoice.id}`;
    },
  },
};
</script>
